import config from 'config';
import { http_client } from './api';

const getList = async (latihanID, sessionID, onDownload) => {
  return http_client.get(config.apiUrl + 'latihan/' + latihanID + '/' + sessionID, null, onDownload).then((response) => {
    return response.data;
  });
};

const read = async (latihanID, id) => {
  return http_client.get(config.apiUrl + 'latihan/' + latihanID + '/soal/' + id).then((response) => {
    return response.data;
  });
};

const storeAnswer = async (data) => {
  return http_client.post(config.apiUrl + 'latihan/soal/jawaban', data).then((response) => {
    return response.data;
  });
};

const storeAnswerJapen = async (data) => {
  return http_client.post(config.apiUrl + 'latihan/soal/jawaban/japen', data).then((response) => {
    return response.data;
  });
};

const storeAnswerHafalan = async (data) => {
  return http_client.post(config.apiUrl + 'latihan/soal/jawaban/hafalan', data).then((response) => {
    return response.data;
  });
};

const storeAnswerRangkata = async (data) => {
  return http_client.post(config.apiUrl + 'latihan/soal/jawaban/rangkata', data).then((response) => {
    return response.data;
  });
};

const storeAnswerPilganKompleks = async (data) => {
  return http_client.post(config.apiUrl + 'latihan/soal/jawaban/pilgankompleks', data).then((response) => {
    return response.data;
  });
};

const getListPilihan = async (latihanID, soalID) => {
  return http_client.get(config.apiUrl + 'latihan/' + latihanID + '/soal/' + soalID + '/pilihan').then((response) => {
    return response.data;
  });
};

const SoalService = {
  getList,
  read,
  storeAnswer,
  storeAnswerJapen,
  storeAnswerHafalan,
  storeAnswerRangkata,
  storeAnswerPilganKompleks,
  getListPilihan
};

export default SoalService;
