import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hari: [
    { value: 'Senin', label: 'Senin' },
    { value: 'Selasa', label: 'Selasa' },
    { value: 'Rabu', label: 'Rabu' },
    { value: 'Kamis', label: 'Kamis' },
    { value: 'Jumat', label: 'Jumat' },
    { value: 'Sabtu', label: 'Sabtu' },
  ],
  jenjang: [
    { value: 'SD', label: 'SD' },
    { value: 'SMP', label: 'SMP' },
    { value: 'SMA', label: 'SMA' }
  ],
  gender: [
    { value: 'L', label: 'Laki-Laki' },
    { value: 'P', label: 'Perempuan' }
  ],
  agama: [
    { value: 'Islam', label: 'Islam' },
    { value: 'Protestan', label: 'Protestan' },
    { value: 'Katolik', label: 'Katolik' },
    { value: 'Hindu', label: 'Hindu' },
    { value: 'Buddha', label: 'Buddha' },
    { value: 'Konghucu', label: 'Konghucu' },
  ],
  student: [
    { value: 'baru', label: 'Murid Baru' },
    { value: 'lama', label: 'Murid Lama' }
  ],
  subscription: [
    { value: 'harian', label: 'Harian' },
    { value: 'bulanan', label: 'Bulanan' },
    { value: 'semester', label: 'Semester' },
    { value: 'tahunan', label: 'Tahunan' }
  ],
  class: [
    { value: 'online', label: 'Online' },
    { value: 'offline', label: 'Offline' }
  ],
  school: [
    { value: 'umum', label: 'Umum' },
    { value: 'international', label: 'International' }
  ]
};

const enumsSlice = createSlice({
  name: 'enums',
  initialState
});

const { reducer } = enumsSlice;

export default reducer;
