import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import MasterDataSevices from '../../services/masterDataServices';
import { clearAuth } from 'store/auth';

export const getTahunAjaran = createAsyncThunk('master/tahunajaran', async (_, thunkAPI) => {
  try {
    const response = await MasterDataSevices.tahunajaran();

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getSekolah = createAsyncThunk('master/sekolah', async (jenjang, thunkAPI) => {
  try {
    const response = await MasterDataSevices.sekolah(jenjang);

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getCabang = createAsyncThunk('master/cabang', async (_, thunkAPI) => {
  try {
    const response = await MasterDataSevices.cabang();

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getJenjang = createAsyncThunk('master/jenjang', async (_, thunkAPI) => {
  try {
    const response = await MasterDataSevices.jenjang();

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getMapel = createAsyncThunk('master/mapel', async (jenjangID, thunkAPI) => {
  try {
    const response = await MasterDataSevices.mapel(jenjangID);

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getBab = createAsyncThunk('master/bab', async (mapelID, thunkAPI) => {
  try {
    const response = await MasterDataSevices.bab(mapelID);

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getSubbab = createAsyncThunk('master/subbab', async (babID, thunkAPI) => {
  try {
    const response = await MasterDataSevices.subbab(babID);

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getStudentType = createAsyncThunk('master/student', async (_, thunkAPI) => {
  try {
    const response = await MasterDataSevices.studentType();

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getSubscriptionType = createAsyncThunk('master/subscription', async (except = null, thunkAPI) => {
  try {
    const response = await MasterDataSevices.subscriptionType(except);

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getClassType = createAsyncThunk('master/class', async (_, thunkAPI) => {
  try {
    const response = await MasterDataSevices.classType();

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getSchoolType = createAsyncThunk('master/school', async (_, thunkAPI) => {
  try {
    const response = await MasterDataSevices.schoolType();

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getBase64File = createAsyncThunk('master/base62', async (data, thunkAPI) => {
  try {
    const response = await MasterDataSevices.getBase64File(data);

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

const initialState = {
  isLoading: true,
  tahunajaran: [],
  sekolah: [],
  cabang: [],
  jenjang: [],
  mapel: [],
  bab: [],
  subbab: [],
  student: [],
  subscription: [],
  class: [],
  school: [],
  file: null
};

const masterSlice = createSlice({
  name: 'master',
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getTahunAjaran.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getTahunAjaran.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tahunajaran = action.payload;
      }),
      builder.addCase(getTahunAjaran.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getSekolah.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getSekolah.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sekolah = action.payload;
      }),
      builder.addCase(getSekolah.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getCabang.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getCabang.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cabang = action.payload;
      }),
      builder.addCase(getCabang.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getJenjang.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getJenjang.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jenjang = action.payload;
      }),
      builder.addCase(getJenjang.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getMapel.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getMapel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.mapel = action.payload;
      }),
      builder.addCase(getMapel.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getBab.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getBab.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bab = action.payload;
      }),
      builder.addCase(getBab.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getSubbab.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getSubbab.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subbab = action.payload;
      }),
      builder.addCase(getSubbab.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getStudentType.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getStudentType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.student = action.payload;
      }),
      builder.addCase(getStudentType.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getSubscriptionType.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getSubscriptionType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscription = action.payload;
      }),
      builder.addCase(getSubscriptionType.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getClassType.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getClassType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.class = action.payload;
      }),
      builder.addCase(getClassType.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getSchoolType.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getSchoolType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.school = action.payload;
      }),
      builder.addCase(getSchoolType.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getBase64File.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getBase64File.fulfilled, (state, action) => {
        state.isLoading = false;
        state.file = action.payload;
      }),
      builder.addCase(getBase64File.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer } = masterSlice;

export default reducer;
