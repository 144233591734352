import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const VideoPage = Loadable(lazy(() => import('views/video')));
const VideoSessionPage = Loadable(lazy(() => import('views/video/session')));

const MateriPage = Loadable(lazy(() => import('views/materi')));
const MateriDetailPage = Loadable(lazy(() => import('views/materi/detail')));
const WebviewReader = Loadable(lazy(() => import('views/materi/component/WebviewReader')));
const WebviewReaderAlt = Loadable(lazy(() => import('views/materi/component/WebviewReaderAlt')));

const LatihanPage = Loadable(lazy(() => import('views/latihan')));
const HistoryPage = Loadable(lazy(() => import('views/latihan/history')));

const MatchWordPage = Loadable(lazy(() => import('views/matchword')));
const MatchWordHistoryPage = Loadable(lazy(() => import('views/matchword/history')));
const MatchWordSessionPage = Loadable(lazy(() => import('views/matchword/session')));

const SwmPage = Loadable(lazy(() => import('views/studywithme')));
const SwmHistoryPage = Loadable(lazy(() => import('views/studywithme/history')));
const SwmSessionPage = Loadable(lazy(() => import('views/studywithme/session')));

const ExamArchPage = Loadable(lazy(() => import('views/examarch')));
const ExamArchHistoryPage = Loadable(lazy(() => import('views/examarch/history')));
const ExamArchSessionPage = Loadable(lazy(() => import('views/examarch/session')));
const ExamArchPembahasanPage = Loadable(lazy(() => import('views/examarch/session/PembahasanSoal')));

const SoalPage = Loadable(lazy(() => import('views/soal')));
const PembahasanSoalPage = Loadable(lazy(() => import('views/soal/PembahasanSoal')));
const SoalHafalanPage = Loadable(lazy(() => import('views/soal/hafalan')));
const SoalRangkataPage = Loadable(lazy(() => import('views/soal/rangkata')));
const PilganKompleksPage = Loadable(lazy(() => import('views/soal/PilganKompleks')));
const JapenPage = Loadable(lazy(() => import('views/soal/Japen')));

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/auth/Login')));
const AuthRegister = Loadable(lazy(() => import('views/auth/Register')));
const WelcomePage = Loadable(lazy(() => import('views/auth/welcome')));

// profile routing
const ProfilePage = Loadable(lazy(() => import('views/profile')));
const ProfileEditPage = Loadable(lazy(() => import('views/profile/edit')));

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // return useRoutes([MainRoutes, AuthenticationRoutes]);
  return (
    <Routes>
      <Route path="pdf" element={<WebviewReaderAlt />} />
      <Route path="materi/:id" element={<WebviewReader />} />
      <Route path="rangkata/:latihanID/:sessionID" element={<SoalRangkataPage />} />
      <Route path="matchword/:id/:sessionID" element={<MatchWordSessionPage />} />
      <Route path="studywithme/:id/:sessionId/:stageIdx/:itemIdx" element={<SwmSessionPage />} />

      <Route element={<MinimalLayout />}>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route path="/login" element={<AuthLogin />} />
        <Route path="/register" element={<AuthRegister />} />
        <Route path="/welcome/:code" element={<WelcomePage />} />
      </Route>

      <Route element={<MainLayout />}>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />

        <Route path="profile" element={<ProfilePage />} />
        <Route path="profile/edit" element={<ProfileEditPage />} />

        <Route path="/learning">
          <Route path="latihan/:type" element={<LatihanPage />} />
          <Route path="latihan/:latihanID/history" element={<HistoryPage />} />
          <Route path="latihan/:latihanID/:sessionID" element={<SoalPage />} />
          <Route path="latihan/:latihanID/:sessionID/pembahasan" element={<PembahasanSoalPage />} />
          <Route path="latihan/:latihanID/:sessionID/japen" element={<JapenPage />} />
          <Route path="latihan/:latihanID/:sessionID/hafalan" element={<SoalHafalanPage />} />
          <Route path="latihan/:latihanID/:sessionID/rangkata" element={<SoalRangkataPage />} />
          <Route path="latihan/:latihanID/:sessionID/pilgankompleks" element={<PilganKompleksPage />} />

          <Route path="video" element={<VideoPage />} />
          <Route path="video/:id/:sessionId" element={<VideoSessionPage />} />

          <Route path="materi" element={<MateriPage />} />
          <Route path="materi/:id" element={<MateriDetailPage />} />

          <Route path="matchword" element={<MatchWordPage />} />
          <Route path="matchword/:id/history" element={<MatchWordHistoryPage />} />
          <Route path="matchword/:id/:sessionID" element={<MatchWordSessionPage />} />

          <Route path="studywithme" element={<SwmPage />} />
          <Route path="studywithme/:id/history" element={<SwmHistoryPage />} />
          <Route path="studywithme/:id/:sessionId/:stageIdx/:itemIdx" element={<SwmSessionPage />} />

          <Route path="examarch" element={<ExamArchPage />} />
          <Route path="examarch/:id/history" element={<ExamArchHistoryPage />} />
          <Route path="examarch/:id/session/:sessionId/" element={<ExamArchSessionPage />} />
          <Route path="examarch/:id/session/:sessionId/pembahasan" element={<ExamArchPembahasanPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
}
