import config from 'config';
import { http_client } from './api';

const getList = async (
  student_type_id = null,
  subscription_type_id = null,
  class_type_id = null,
  school_type_id = null,
  tahun_ajaran_id = null,
  jenjang_id = null,
  cabang_id = null
) => {
  return http_client
    .get(
      config.apiUrl +
        'harga?' +
        `${student_type_id ? '&student_type_id=' + student_type_id : ''}` +
        `${subscription_type_id ? '&subscription_type_id=' + subscription_type_id : ''}` +
        `${class_type_id ? '&class_type_id=' + class_type_id : ''}` +
        `${school_type_id ? '&school_type_id=' + school_type_id : ''}` +
        `${tahun_ajaran_id ? '&tahun_ajaran_id=' + tahun_ajaran_id : ''}` +
        `${jenjang_id ? '&jenjang_id=' + jenjang_id : ''}` +
        `${cabang_id ? '&cabang_id=' + cabang_id : ''}`
    )
    .then((response) => {
      return response.data;
    });
};
const getNewPrice = async (
  subscription_type_id = null,
  class_type_id = null,
  school_type_id = null,
  jenjang_id = null,
  cabang_id = null
) => {
  return http_client
    .get(
      config.apiUrl +
        'harga/new?' +
        `${subscription_type_id ? '&subscription_type_id=' + subscription_type_id : ''}` +
        `${class_type_id ? '&class_type_id=' + class_type_id : ''}` +
        `${school_type_id ? '&school_type_id=' + school_type_id : ''}` +
        `${jenjang_id ? '&jenjang_id=' + jenjang_id : ''}` +
        `${cabang_id ? '&cabang_id=' + cabang_id : ''}`
    )
    .then((response) => {
      return response.data;
    });
};
const read = async (id) => {
  return http_client.get(config.apiUrl + 'harga/' + id).then((response) => {
    return response.data;
  });
};

const HargaService = {
  getList,
  read,
  getNewPrice
};

export default HargaService;
