import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import MateriServices from '../../services/materiServices';
import { clearAuth } from 'store/auth';

export const getList = createAsyncThunk(
  'materi/list',
  async ({ page, perPage, sort, orderBy, search, jenjang_id, mapel_id, bab_id }, thunkAPI) => {
    try {
      const response = await MateriServices.getList(page, perPage, sort, orderBy, search, jenjang_id, mapel_id, bab_id);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        thunkAPI.dispatch(clearAuth());
      }
      const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: 'error', code: error?.response?.status }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const readData = createAsyncThunk('materi/read', async (id, thunkAPI) => {
  try {
    const response = await MateriServices.read(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error', code: error?.response?.status }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getFile = createAsyncThunk('materi/getFile', async (id, thunkAPI) => {
  try {
    const response = await MateriServices.getFile(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error', code: error?.response?.status }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getRating = createAsyncThunk('materi/getRating', async (id, thunkAPI) => {
  try {
    const response = await MateriServices.getRating(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const storeRating = createAsyncThunk('materi/storeRating', async (data, thunkAPI) => {
  try {
    const response = await MateriServices.storeRating(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

const initialState = { isLoading: true, isFileLoading: true, file: null, list: [], data: [], group: [], error: [], rating: null };

const materiSlice = createSlice({
  name: 'materi',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
        state.data = null;
        state.group = [];
        state.error = [];
      }),
      builder.addCase(getList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.list = [];
        state.group = [];
        state.error = [];
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getFile.pending, (state) => {
        state.isFileLoading = true;
        state.file = null;
      }),
      builder.addCase(getFile.fulfilled, (state, action) => {
        state.isFileLoading = false;
        state.file = action.payload;
      }),
      builder.addCase(getFile.rejected, (state) => {
        state.isFileLoading = false;
        state.file = null;
      }),
      builder.addCase(getRating.pending, (state) => {
        state.isLoading = true;
        state.rating = null;
      }),
      builder.addCase(getRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rating = action?.payload;
      }),
      builder.addCase(getRating.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(storeRating.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer } = materiSlice;
export default reducer;
