// assets
import { IconBrandYoutube, IconFile, IconNews } from '@tabler/icons';

// ==============================|| LEARNING MENU ITEMS ||============================== //

const learning = {
  id: 'learning',
  title: 'Learning',
  type: 'group',
  children: [
    {
      id: 'pilgan',
      title: 'Pilihan Ganda',
      type: 'item',
      url: '/learning/latihan/pilgan',
      icon: IconNews,
      breadcrumbs: false
    },
    {
      id: 'pilgankompleks',
      title: 'Pilihan Ganda Kompleks',
      type: 'item',
      url: '/learning/latihan/pilgankompleks',
      icon: IconNews,
      breadcrumbs: false
    },
    {
      id: 'essay',
      title: 'Essay',
      type: 'item',
      url: '/learning/latihan/essay',
      icon: IconNews,
      breadcrumbs: false
    },
    {
      id: 'hafalan',
      title: 'Hafalan',
      type: 'item',
      url: '/learning/latihan/hafalan',
      icon: IconNews,
      breadcrumbs: false
    },
    {
      id: 'rangkata',
      title: 'Rangkai Kata',
      type: 'item',
      url: '/learning/latihan/rangkata',
      icon: IconNews,
      breadcrumbs: false
    },

    {
      id: 'matchword',
      title: 'Matching Words',
      type: 'item',
      url: '/learning/matchword',
      icon: IconNews,
      breadcrumbs: false
    },
    {
      id: 'japen',
      title: 'Jawaban Pendek',
      type: 'item',
      url: '/learning/latihan/japen',
      icon: IconNews,
      breadcrumbs: false
    },
    {
      id: 'video',
      title: 'Video Belajar',
      type: 'item',
      url: '/learning/video',
      icon: IconBrandYoutube,
      breadcrumbs: false
    },
    {
      id: 'materi',
      title: 'Rangkuman Materi',
      type: 'item',
      url: '/learning/materi',
      icon: IconFile,
      breadcrumbs: false
    },
    {
      id: 'studywithme',
      title: 'Study With Me',
      type: 'item',
      url: '/learning/studywithme',
      icon: IconNews,
      breadcrumbs: false
    },
    {
      id: 'examarch',
      title: 'Soal Tahun Lalu',
      type: 'item',
      url: '/learning/examarch',
      icon: IconNews,
      breadcrumbs: false
    }
  ]
};

export default learning;
