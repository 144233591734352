import config from 'config';
import { http_client } from './api';

const getList = async (
  page = null,
  perPage = null,
  sort = null,
  orderBy = null,
  search = null,
  jenjang_id = null,
  mapel_id = null,
  type = null
) => {
  return http_client
    .get(
      config.apiUrl +
        'examarch?' +
        `${perPage ? 'perPage=' + perPage : ''}` +
        `${page ? '&page=' + page : ''}` +
        `${orderBy ? '&orderBy=' + orderBy : ''}` +
        `${sort ? '&sort=' + sort : ''}` +
        `${search ? '&search=' + search : ''}` +
        `${jenjang_id ? '&jenjang_id=' + jenjang_id : ''}` +
        `${mapel_id ? '&mapel_id=' + mapel_id : ''}` +
        `${type ? '&type=' + type : ''}`
    )
    .then((response) => {
      return response.data;
    });
};

const read = async (id) => {
  return http_client.get(config.apiUrl + 'examarch/' + id).then((response) => {
    return response.data;
  });
};

const createSession = async (id, data) => {
  return http_client.post(config.apiUrl + 'examarch/' + id + '/session', data).then((response) => {
    return response.data;
  });
};

const repeatSession = async (id) => {
  return http_client.patch(config.apiUrl + 'examarch/' + id + '/session').then((response) => {
    return response.data;
  });
};

const getSession = async (id, sessionId) => {
  return http_client.get(config.apiUrl + 'examarch/' + id + '/session/' + sessionId).then((response) => {
    return response.data;
  });
};

const closeSession = async (id, sessionId) => {
  return http_client.post(config.apiUrl + 'examarch/' + id + '/session/' + sessionId).then((response) => {
    return response.data;
  });
};

const getHistory = async (id) => {
  return http_client.get(config.apiUrl + 'examarch/' + id + '/history').then((response) => {
    return response.data;
  });
};

const storeAnswer = async (id, sessionId, questionId, data) => {
  return http_client.post(config.apiUrl + 'examarch/' + id + '/session/' + sessionId + '/question/' + questionId, data).then((response) => {
    return response.data;
  });
};

const getRating = async (id) => {
  return http_client.get(config.apiUrl + 'examarch/' + id + '/rating').then((response) => {
    return response.data;
  });
};

const storeRating = async (id, data) => {
  return http_client.post(config.apiUrl + 'examarch/' + id + '/rating', data).then((response) => {
    return response.data;
  });
};

const ExamArchService = {
  getList,
  read,
  createSession,
  repeatSession,
  getSession,
  closeSession,
  getHistory,
  storeAnswer,
  getRating,
  storeRating
};

export default ExamArchService;
