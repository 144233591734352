import config from 'config';
import { http_client } from './api';

const getList = async (
  page = null,
  perPage = null,
  sort = null,
  orderBy = null,
  search = null,
  jenjang_id = null,
  mapel_id = null,
  bab_id = null
) => {
  return http_client
    .get(
      config.apiUrl +
        'materi?' +
        `${perPage ? 'perPage=' + perPage : ''}` +
        `${page ? '&page=' + page : ''}` +
        `${orderBy ? '&orderBy=' + orderBy : ''}` +
        `${sort ? '&sort=' + sort : ''}` +
        `${search ? '&search=' + search : ''}` +
        `${jenjang_id ? '&jenjang_id=' + jenjang_id : ''}` +
        `${mapel_id ? '&mapel_id=' + mapel_id : ''}` +
        `${bab_id ? '&bab_id=' + bab_id : ''}`
    )
    .then((response) => {
      return response.data;
    });
};

const read = async (id) => {
  return http_client.get(config.apiUrl + 'materi/' + id).then((response) => {
    return response.data;
  });
};

const getFile = async (id) => {
  return http_client.get(config.apiUrl + 'materi/file/' + id).then((response) => {
    return response.data;
  });
};

const getRating = async (id) => {
  return http_client.get(config.apiUrl + 'materi/' + id + '/rating').then((response) => {
    return response.data;
  });
};

const storeRating = async (id, data) => {
  return http_client.post(config.apiUrl + 'materi/' + id + '/rating', data).then((response) => {
    return response.data;
  });
};

const MateriService = {
  getList,
  read,
  getFile,
  getRating,
  storeRating
};

export default MateriService;
