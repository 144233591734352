import config from 'config';
import { http_client } from './api';

const profile = async () => {
  return http_client.get(config.apiUrl + 'profile/').then((response) => {
    return response.data;
  });
};
const update = async (data) => {
  return http_client.post(config.apiUrl + 'profile/', data).then((response) => {
    localStorage.setItem('student', JSON.stringify(response.data.data));
    return response.data;
  });
};

const updatePhoto = async (data) => {
  return http_client.post(config.apiUrl + 'profile/photo/', data).then((response) => {
    localStorage.setItem('student', JSON.stringify(response.data.data));
    return response.data;
  });
};

const deletePhoto = async () => {
  return http_client.delete(config.apiUrl + 'profile/photo/').then((response) => {
    localStorage.setItem('student', JSON.stringify(response.data.data));
    return response.data;
  });
};

const ProfileService = {
  profile,
  update,
  updatePhoto,
  deletePhoto
};

export default ProfileService;
