import config from 'config';
import { http_client } from './api';

const getList = async (page = null, perPage = null, sort = null, orderBy = null, search = null) => {
  return http_client
    .get(
      config.apiUrl +
        'swm?' +
        `${perPage ? 'perPage=' + perPage : ''}` +
        `${page ? '&page=' + page : ''}` +
        `${orderBy ? '&orderBy=' + orderBy : ''}` +
        `${sort ? '&sort=' + sort : ''}` +
        `${search ? '&search=' + search : ''}`
    )
    .then((response) => {
      return response.data;
    });
};
const read = async (id) => {
  return http_client.get(config.apiUrl + 'swm/' + id).then((response) => {
    return response.data;
  });
};

const createSession = async (id, data) => {
  return http_client.post(config.apiUrl + 'swm/' + id, data).then((response) => {
    return response.data;
  });
};

const closeSession = async (id, sessionId) => {
  return http_client.post(config.apiUrl + 'swm/' + id + '/' + sessionId).then((response) => {
    return response.data;
  });
};

const getHistory = async (id) => {
  return http_client.get(config.apiUrl + 'swm/' + id + '/history').then((response) => {
    return response.data;
  });
};

const getSession = async (id, sessionId, stageIndex, itemIndex) => {
  return http_client.get(config.apiUrl + 'swm/' + id + '/' + sessionId + '/' + stageIndex + '/' + itemIndex).then((response) => {
    return response.data;
  });
};
const saveItemSession = async (id, sessionId, stageIndex, itemIndex, data) => {
  return http_client.post(config.apiUrl + 'swm/' + id + '/' + sessionId + '/' + stageIndex + '/' + itemIndex, data).then((response) => {
    return response.data;
  });
};

const createExerciseSessionItem = async (id, sessionId, stageIndex, itemIndex) => {
  return http_client
    .post(config.apiUrl + 'swm/' + id + '/' + sessionId + '/' + stageIndex + '/' + itemIndex + '/exercise')
    .then((response) => {
      return response.data;
    });
};

const getExerciseSoal = async (id, sessionId, stageIndex, itemIndex, sessionItemId) => {
  return http_client
    .get(config.apiUrl + 'swm/' + id + '/' + sessionId + '/' + stageIndex + '/' + itemIndex + '/exercise/' + sessionItemId)
    .then((response) => {
      return response.data;
    });
};

const storeExerciseAnswer = async (id, sessionId, stageIndex, itemIndex, sessionItemId, data) => {
  return http_client
    .post(config.apiUrl + 'swm/' + id + '/' + sessionId + '/' + stageIndex + '/' + itemIndex + '/exercise/' + sessionItemId, data)
    .then((response) => {
      return response.data;
    });
};

const closeExerciseSessionItem = async (id, sessionId, stageIndex, itemIndex, sessionItemId, data) => {
  return http_client
    .patch(config.apiUrl + 'swm/' + id + '/' + sessionId + '/' + stageIndex + '/' + itemIndex + '/exercise/' + sessionItemId, data)
    .then((response) => {
      return response.data;
    });
};

const getRating = async (id) => {
  return http_client.get(config.apiUrl + 'swm/' + id + '/rating').then((response) => {
    return response.data;
  });
};
const storeRating = async (id, data) => {
  return http_client.post(config.apiUrl + 'swm/' + id + '/rating', data).then((response) => {
    return response.data;
  });
};

const swmService = {
  getList,
  read,
  createSession,
  closeSession,
  getSession,
  getHistory,
  saveItemSession,
  createExerciseSessionItem,
  getExerciseSoal,
  storeExerciseAnswer,
  closeExerciseSessionItem,
  getRating,
  storeRating
};

export default swmService;
