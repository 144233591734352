import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import AuthService from '../../services/authServices';

const user = localStorage.getItem('student') != undefined ? JSON.parse(localStorage.getItem('student')) : null;
const token = localStorage.getItem('studentToken');

export const register = createAsyncThunk('auth/register', async (data, thunkAPI) => {
  try {
    const response = await AuthService.register(data);
    const message = (response && response.data && response.data.message) || response.message || response.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'success' }));
    return response.data;
  } catch (error) {
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const login = createAsyncThunk('auth/login', async ({ email, password }, thunkAPI) => {
  try {
    const data = await AuthService.login(email, password);
    return { user: data };
  } catch (error) {
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const logout = createAsyncThunk('auth/logout', async (param, thunkAPI) => {
  try {
    await AuthService.logout();
  } catch (error) {
    thunkAPI.dispatch(clearAuth());
  }
});
export const clearAuth = createAsyncThunk('auth/clearAuth', async () => {
  await AuthService.clearAuth();
});

const initialState = token
  ? { isLoggedIn: true, isLoading: false, token, user, error: [] }
  : { isLoggedIn: false, isLoading: false, token: null, user: null, error: [] };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(register.fulfilled, (state) => {
        state.isLoading = false;
        state.isLoggedIn = false;
      }),
      builder.addCase(register.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.isLoading = false;
        state.error = action.payload?.errors ?? [];
      }),
      builder.addCase(login.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.isLoading = false;
        state.token = action.payload.token;
        state.user = action.payload.user;
      }),
      builder.addCase(login.rejected, (state) => {
        state.isLoggedIn = false;
        state.isLoading = false;
        state.token = null;
        state.user = null;
        state.permission = null;
      }),
      builder.addCase(logout.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.isLoading = false;
        state.token = null;
        state.user = null;
        state.permission = null;
      }),
      builder.addCase(clearAuth.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(clearAuth.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.isLoading = false;
        state.token = null;
        state.user = null;
        state.permission = null;
      });
  }
});

const { reducer } = authSlice;
export default reducer;
