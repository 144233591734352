import { createSlice } from '@reduxjs/toolkit';

// project imports
import config from 'config';

const initialState = {
  isOpen: [], // for active default menu
  defaultId: 'default',
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true
};

const customizationSlice = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    openMenu: (state, action) => {
      return {
        ...state,
        isOpen: [action.payload]
      };
    },
    setMenu: (state, action) => {
      return {
        ...state,
        opened: action.payload
      };
    },
    setFontFamily: (state, action) => {
      return {
        ...state,
        fontFamily: action.payload
      };
    },
    setBorderRadius: (state, action) => {
      return {
        ...state,
        borderRadius: action.payload
      };
    }
  }
});

const { reducer, actions } = customizationSlice;

export const { openMenu, setMenu, setFontFamily, setBorderRadius } = actions;
export default reducer;
