import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { clearMessage } from 'store/message';

// project imports
// import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const dispatch = useDispatch();

  const message = useSelector((state) => state.message);
  const auth = useSelector((state) => state.auth);

  const handleClose = () => {
    dispatch(clearMessage());
  };

  if (auth.isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }
  

  return (
    <>
      <Snackbar
        open={message?.message != '' ? true : false}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key="minimalPageALert"
      >
        <Alert onClose={handleClose} variant="filled" severity={message?.type != '' ? message.type : 'info'} sx={{ width: '100%' }}>
          <AlertTitle>{message?.type != '' ? message.type.toUpperCase() : 'INFO'}</AlertTitle>
          <strong>
          {message.message}
          </strong>
        </Alert>
      </Snackbar>
      <Outlet />
      {/* <Customization /> */}
    </>
  );
};

export default MinimalLayout;
