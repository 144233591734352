import { configureStore } from '@reduxjs/toolkit';
import customizationReducer from './customization';
import enumsReducer from './enums';
import authReducer from './auth';
import messageReducer from './message';
import wilayahReducer from './wilayah';
import hargaReducer from './harga';
import latihanReducer from './latihan';
import materiReducer from './materi';
import matchwordReducer from './matchword';
import videoReducer from './video';
import soalReducer from './soal';
import paymentReducer from './payment';
import masterReducer from './master';
import profileReducer from './profile';
import studywithmeReducer from './studywithme';
import examarchReducer from './examarch';

// ==============================|| REDUX - MAIN STORE ||============================== //

const reducer = {
  profile: profileReducer,
  auth: authReducer,
  master: masterReducer,
  latihan: latihanReducer,
  materi: materiReducer,
  matchword: matchwordReducer,
  studywithme: studywithmeReducer,
  examarch: examarchReducer,
  video: videoReducer,
  soal: soalReducer,
  payment: paymentReducer,
  harga: hargaReducer,
  wilayah: wilayahReducer,
  message: messageReducer,
  customization: customizationReducer,
  enums: enumsReducer
};
export const store = configureStore({
  reducer: reducer,
  devTools: true
});
