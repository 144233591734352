import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  type: ''
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      let message = {
        message: action.payload?.message,
        type: action.payload?.type,
        code: action.payload?.code
      };
      window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
      return {
        ...state,
        ...message
      };
    },
    clearMessage: (state) => {
      return {
        ...state,
        message: '',
        type: ''
      };
    }
  }
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;
export default reducer;
