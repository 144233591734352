import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import HargaService from '../../services/hargaServices';
import { clearAuth } from 'store/auth';

export const getList = createAsyncThunk(
  'harga/list',
  async ({ student_type_id, subscription_type_id, class_type_id, school_type_id, tahun_ajaran_id, jenjang_id, cabang_id }, thunkAPI) => {
    try {
      const response = await HargaService.getList(
        student_type_id,
        subscription_type_id,
        class_type_id,
        school_type_id,
        tahun_ajaran_id,
        jenjang_id,
        cabang_id
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        thunkAPI.dispatch(clearAuth());
      }
      const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getNewPrice = createAsyncThunk(
  'harga/getNewPrice',
  async ({ subscription_type_id, class_type_id, school_type_id, jenjang_id, cabang_id }, thunkAPI) => {
    try {
      const response = await HargaService.getNewPrice(subscription_type_id, class_type_id, school_type_id, jenjang_id, cabang_id);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        thunkAPI.dispatch(clearAuth());
      }
      const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const readData = createAsyncThunk('harga/read', async (id, thunkAPI) => {
  try {
    const response = await HargaService.read(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

const initialState = { isLoading: true, list: [], data: [], error: [] };

const hargaSlice = createSlice({
  name: 'harga',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      }),
      builder.addCase(getList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getNewPrice.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getNewPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      }),
      builder.addCase(getNewPrice.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer } = hargaSlice;
export default reducer;
