import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import ProfileService from '../../services/profileServices';
import { clearAuth } from 'store/auth';

const user = JSON.parse(localStorage.getItem('student'));

export const readData = createAsyncThunk('profile/read', async (_, thunkAPI) => {
  try {
    const response = await ProfileService.profile();
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const updateData = createAsyncThunk('profile/update', async (data, thunkAPI) => {
  try {
    const response = await ProfileService.update(data);
    const message = (response && response.data && response.data.message) || response.message || response.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'success' }));
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }

    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const updatePhoto = createAsyncThunk('profile/updatePhoto', async ({ photo }, thunkAPI) => {
  try {
    const response = await ProfileService.updatePhoto(photo);
    const message = (response && response.data && response.data.message) || response.message || response.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'success' }));
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const deletePhoto = createAsyncThunk('profile/deletePhoto', async (_, thunkAPI) => {
  try {
    const response = await ProfileService.deletePhoto();
    const message = (response && response.data && response.data.message) || response.message || response.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'success' }));
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

const initialState = { isLoading: false, data: user, error: [] };

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(readData.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = [];
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(updateData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(updateData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = [];
      }),
      builder.addCase(updateData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.errors;
      }),
      builder.addCase(updatePhoto.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(updatePhoto.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = [];
      }),
      builder.addCase(updatePhoto.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(deletePhoto.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(deletePhoto.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = [];
      }),
      builder.addCase(deletePhoto.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer } = profileSlice;
export default reducer;
