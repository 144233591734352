import { http_client } from './api';
import config from 'config';

const register = async (data) => {
  return http_client.post('register', data).then((response) => {
    // localStorage.setItem('studentToken', response.data.data.token);
    // localStorage.setItem('student', JSON.stringify(response.data.data.student));
    // window.location.replace('/');
    return response.data;
  });
};

const login = async (email, password) => {
  return http_client.get(config.baseApiUrl + 'sanctum/csrf-cookie').then(() => {
    return http_client
      .post('login', {
        email,
        password
      })
      .then((response) => {
        localStorage.setItem('studentToken', response.data.data.token);
        localStorage.setItem('student', JSON.stringify(response.data.data.student));
        window.location.replace('/');
        return response.data;
      });
  });
};

const logout = async () => {
  return http_client.post('logout').then((response) => {
    localStorage.removeItem('studentToken');
    localStorage.removeItem('student');
    window.location.replace('/login');
    return response.data;
  });
};
const clearAuth = () => {
  localStorage.removeItem('studentToken');
  localStorage.removeItem('student');
  return window.location.replace('/login');
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('student'));
};

const AuthService = {
  register,
  login,
  logout,
  clearAuth,
  getCurrentUser
};

export default AuthService;
