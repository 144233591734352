const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  baseApiUrl: process.env.REACT_APP_BASE_URL,
  apiUrl: process.env.REACT_APP_BASE_URL + 'api/student/',
  basename: '',
  defaultPath: '/dashboard',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  logo: '/assets/logo.png',
  logoHarizontal: '/assets/logo-harizontal.png'
};

export default config;
