import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import WilayahSevices from '../../services/wilayahServices';
import { clearAuth } from 'store/auth';

export const getProvList = createAsyncThunk('wilayah/provList', async (param, thunkAPI) => {
  try {
    const response = await WilayahSevices.getProvList();

    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const getKabList = createAsyncThunk('wilayah/kabList', async (provID, thunkAPI) => {
  try {
    const response = await WilayahSevices.getKabList(provID);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});
export const readProv = createAsyncThunk('wilayah/provinsi', async (id, thunkAPI) => {
  try {
    const response = await WilayahSevices.readProv(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});
export const readKab = createAsyncThunk('wilayah/kabupaten', async ({ provID, id }, thunkAPI) => {
  try {
    const response = await WilayahSevices.readKab(provID, id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

const initialState = {
  isLoading: true,
  provinsiList: [],
  provinsi: [],
  kabupatenList: [],
  kabupaten: []
};

const authSlice = createSlice({
  name: 'wilayah',
  initialState,
  reducers: {
    setKabList: (state, action) => {
      return {
        ...state,
        kabupatenList: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProvList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getProvList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.provinsiList = action.payload;
      }),
      builder.addCase(getProvList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getKabList.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getKabList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.kabupatenList = action.payload;
      }),
      builder.addCase(getKabList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readProv.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readProv.fulfilled, (state, action) => {
        state.isLoading = false;
        state.provinsi = action.payload;
      }),
      builder.addCase(readProv.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readKab.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readKab.fulfilled, (state, action) => {
        state.isLoading = false;
        state.kabupaten = action.payload;
      }),
      builder.addCase(readKab.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer, actions } = authSlice;
export const { setKabList } = actions;

export default reducer;
