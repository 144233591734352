import config from 'config';
import { http_client } from './api';

const getList = async (page = null, perPage = null, sort = null, orderBy = null, search = null, status = null) => {
  return http_client
    .get(
      config.apiUrl +
        'pembayaran?' +
        `${status ? 'perPage=' + status : ''}` +
        `${perPage ? 'perPage=' + perPage : ''}` +
        `${page ? '&page=' + page : ''}` +
        `${orderBy ? '&orderBy=' + orderBy : ''}` +
        `${sort ? '&sort=' + sort : ''}` +
        `${search ? '&search=' + search : ''}`
    )
    .then((response) => {
      return response.data;
    });
};


const read = async (id) => {
  return http_client.get(config.apiUrl + 'pembayaran/' + id).then((response) => {
    return response.data;
  });
};
const encryptRead = async (code) => {
  return http_client.get(config.apiUrl + 'pembayaran/encrypt/' + code).then((response) => {
    return response.data;
  });
};

const PaymentService = {
  getList,
  read,
  encryptRead
};

export default PaymentService;
