import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import SoalService from '../../services/soalServices';
import { clearAuth } from 'store/auth';

const onProgress = (thunkAPI) => (value) => {
  thunkAPI.dispatch(setProgres(value));
};

export const getList = createAsyncThunk('soal/list', async ({ latihanID, sessionID }, thunkAPI) => {
  try {
    const response = await SoalService.getList(latihanID, sessionID, onProgress(thunkAPI));

    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const readData = createAsyncThunk('soal/read', async ({ latihanID, id }, thunkAPI) => {
  try {
    const response = await SoalService.read(latihanID, id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const storeAnswer = createAsyncThunk('soal/storeAnswer', async (data, thunkAPI) => {
  try {
    const response = await SoalService.storeAnswer(data);
    // const message = (response && response.data && response.data.message) || response.message || response.toString();
    // thunkAPI.dispatch(setMessage({ message: message, type: 'success' }));
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const storeAnswerJapen = createAsyncThunk('soal/storeAnswerJapen', async (data, thunkAPI) => {
  try {
    const response = await SoalService.storeAnswerJapen(data);
    // const message = (response && response.data && response.data.message) || response.message || response.toString();
    // thunkAPI.dispatch(setMessage({ message: message, type: 'success' }));
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const storeAnswerHafalan = createAsyncThunk('soal/storeAnswerHafalan', async (data, thunkAPI) => {
  try {
    const response = await SoalService.storeAnswerHafalan(data);
    // const message = (response && response.data && response.data.message) || response.message || response.toString();
    // thunkAPI.dispatch(setMessage({ message: message, type: 'success' }));
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const storeAnswerRangkata = createAsyncThunk('soal/storeAnswerRangkata', async (data, thunkAPI) => {
  try {
    const response = await SoalService.storeAnswerRangkata(data);

    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const storeAnswerPilganKompleks = createAsyncThunk('soal/storeAnswerPilganKompleks', async (data, thunkAPI) => {
  try {
    const response = await SoalService.storeAnswerPilganKompleks(data);

    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  list: [],
  soal: [],
  latihan: {},
  session: {},
  data: {},
  pilihan: [],
  error: [],
  progress: 0
};

const soalSlice = createSlice({
  name: 'soal',
  initialState,
  reducers: {
    setProgres: (state, action) => {
      return {
        ...state,
        progress: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.soal = action.payload.soal;
        state.latihan = action.payload.latihan;
        state.session = action.payload.session;
      }),
      builder.addCase(getList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeAnswer.pending, (state) => {
        state.isCreateLoading = true;
      }),
      builder.addCase(storeAnswer.fulfilled, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswer.rejected, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswerJapen.pending, (state) => {
        state.isCreateLoading = true;
      }),
      builder.addCase(storeAnswerJapen.fulfilled, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswerJapen.rejected, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswerHafalan.pending, (state) => {
        state.isCreateLoading = true;
      }),
      builder.addCase(storeAnswerHafalan.fulfilled, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswerHafalan.rejected, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswerRangkata.pending, (state) => {
        state.isCreateLoading = true;
      }),
      builder.addCase(storeAnswerRangkata.fulfilled, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswerRangkata.rejected, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswerPilganKompleks.pending, (state) => {
        state.isCreateLoading = true;
      }),
      builder.addCase(storeAnswerPilganKompleks.fulfilled, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswerPilganKompleks.rejected, (state) => {
        state.isCreateLoading = false;
      });
  }
});

const { reducer, actions } = soalSlice;
export const { setProgres } = actions;
export default reducer;
