import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import LatihanServices from '../../services/latihanServices';
import { clearAuth } from 'store/auth';

export const getList = createAsyncThunk('latihan/list', async ({ type, query }, thunkAPI) => {
  try {
    const response = await LatihanServices.getList(type, query);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const readData = createAsyncThunk('latihan/read', async (id, thunkAPI) => {
  try {
    const response = await LatihanServices.read(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const createSession = createAsyncThunk('latihan/createSession', async (data, thunkAPI) => {
  try {
    const response = await LatihanServices.createSession(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const repeatSession = createAsyncThunk('latihan/repeatSession', async (data, thunkAPI) => {
  try {
    const response = await LatihanServices.repeatSession(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const closeSession = createAsyncThunk('latihan/closeSession', async (data, thunkAPI) => {
  try {
    const response = await LatihanServices.closeSession(data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getHistory = createAsyncThunk('latihan/getHistory', async (latihanID, thunkAPI) => {
  try {
    const response = await LatihanServices.getHistory(latihanID);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getRating = createAsyncThunk('latihan/getRating', async (id, thunkAPI) => {
  try {
    const response = await LatihanServices.getRating(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const storeRating = createAsyncThunk('latihan/storeRating', async (data, thunkAPI) => {
  try {
    const response = await LatihanServices.storeRating(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

const initialState = { isLoading: true, list: [], data: [], error: [], rating: null };

const latihanSlice = createSlice({
  name: 'latihan',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      }),
      builder.addCase(getList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(createSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(createSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(createSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(repeatSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(repeatSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(repeatSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(closeSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(closeSession.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(closeSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getHistory.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.latihan;
        state.list = action.payload.session;
      }),
      builder.addCase(getHistory.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getRating.pending, (state) => {
        state.isLoading = true;
        state.rating = null;
      }),
      builder.addCase(getRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rating = action?.payload;
      }),
      builder.addCase(getRating.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(storeRating.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer } = latihanSlice;
export default reducer;
