import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import SwmServices from '../../services/swmServices';
import { clearAuth } from 'store/auth';

export const getList = createAsyncThunk('studywithme/list', async ({ page, perPage, sort, orderBy, search }, thunkAPI) => {
  try {
    const response = await SwmServices.getList(page, perPage, sort, orderBy, search);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const readData = createAsyncThunk('studywithme/read', async (id, thunkAPI) => {
  try {
    const response = await SwmServices.read(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const createSession = createAsyncThunk('studywithme/createSession', async (data, thunkAPI) => {
  try {
    const response = await SwmServices.createSession(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const closeSession = createAsyncThunk('studywithme/closeSession', async ({ id, sessionId }, thunkAPI) => {
  try {
    const response = await SwmServices.closeSession(id, sessionId);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getHistory = createAsyncThunk('studywithme/getHistory', async (studywithmeID, thunkAPI) => {
  try {
    const response = await SwmServices.getHistory(studywithmeID);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getSession = createAsyncThunk('studywithme/getSession', async ({ id, sessionId, stageIdx, itemIdx }, thunkAPI) => {
  try {
    const response = await SwmServices.getSession(id, sessionId, stageIdx, itemIdx);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const saveItemSession = createAsyncThunk(
  'studywithme/saveItemSession',
  async ({ id, sessionId, stageIdx, itemIdx, data }, thunkAPI) => {
    try {
      const response = await SwmServices.saveItemSession(id, sessionId, stageIdx, itemIdx, data);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        thunkAPI.dispatch(clearAuth());
      }
      const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const createExerciseSessionItem = createAsyncThunk(
  'studywithme/createExerciseSessionItem',
  async ({ id, sessionId, stageIdx, itemIdx }, thunkAPI) => {
    try {
      const response = await SwmServices.createExerciseSessionItem(id, sessionId, stageIdx, itemIdx);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        thunkAPI.dispatch(clearAuth());
      }
      const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const getExerciseSoal = createAsyncThunk(
  'studywithme/getExerciseSoal',
  async ({ id, sessionId, stageIdx, itemIdx, sessionItemId }, thunkAPI) => {
    try {
      const response = await SwmServices.getExerciseSoal(id, sessionId, stageIdx, itemIdx, sessionItemId);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        thunkAPI.dispatch(clearAuth());
      }
      const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const storeExerciseAnswer = createAsyncThunk(
  'studywithme/storeExerciseAnswer',
  async ({ id, sessionId, stageIdx, itemIdx, sessionItemId, data }, thunkAPI) => {
    try {
      const response = await SwmServices.storeExerciseAnswer(id, sessionId, stageIdx, itemIdx, sessionItemId, data);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        thunkAPI.dispatch(clearAuth());
      }
      const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const closeExerciseSessionItem = createAsyncThunk(
  'studywithme/closeExerciseSessionItem',
  async ({ id, sessionId, stageIdx, itemIdx, sessionItemId, data }, thunkAPI) => {
    try {
      const response = await SwmServices.closeExerciseSessionItem(id, sessionId, stageIdx, itemIdx, sessionItemId, data);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        thunkAPI.dispatch(clearAuth());
      }
      const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const getRating = createAsyncThunk('studywithme/getRating', async (id, thunkAPI) => {
  try {
    const response = await SwmServices.getRating(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const storeRating = createAsyncThunk('studywithme/storeRating', async (data, thunkAPI) => {
  try {
    const response = await SwmServices.storeRating(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

const initialState = {
  isLoading: true,
  isStoreLoading: false,
  list: [],
  soal: [],
  latihan: null,
  data: null,
  stage: null,
  item: null,
  session: null,
  sessionItems: [],
  sessionItem: null,
  page: [],
  error: [],
  rating: null
};

const studywithmeSlice = createSlice({
  name: 'studywithme',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      }),
      builder.addCase(getList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(createSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(createSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.session = action.payload;
      }),
      builder.addCase(createSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(closeSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(closeSession.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(closeSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getHistory.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.list = action.payload.session;
      }),
      builder.addCase(getHistory.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getSession.pending, (state) => {
        state.isLoading = true;
        state.item = null;
      }),
      builder.addCase(getSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.session = action.payload.session;
        state.stage = action.payload.stage;
        state.item = action.payload.item;
        state.sessionItems = action.payload.sessionItems;
        state.page = action.payload.page;
      }),
      builder.addCase(getSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(saveItemSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(saveItemSession.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(saveItemSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(createExerciseSessionItem.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(createExerciseSessionItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessionItem = action?.payload;
      }),
      builder.addCase(createExerciseSessionItem.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getExerciseSoal.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getExerciseSoal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.latihan = action?.payload?.latihan;
        state.soal = action?.payload?.soal;
        state.sessionItem = action?.payload?.sessionItem;
      }),
      builder.addCase(getExerciseSoal.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeExerciseAnswer.pending, (state) => {
        state.isStoreLoading = true;
      }),
      builder.addCase(storeExerciseAnswer.fulfilled, (state) => {
        state.isStoreLoading = false;
      }),
      builder.addCase(storeExerciseAnswer.rejected, (state) => {
        state.isStoreLoading = false;
      }),
      builder.addCase(closeExerciseSessionItem.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(closeExerciseSessionItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessionItems = action?.payload?.sessionItems;
      }),
      builder.addCase(closeExerciseSessionItem.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getRating.pending, (state) => {
        state.isLoading = true;
        state.rating = null;
      }),
      builder.addCase(getRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rating = action?.payload;
      }),
      builder.addCase(getRating.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(storeRating.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer } = studywithmeSlice;
export default reducer;
