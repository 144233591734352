import config from 'config';
import { http_client } from './api';

const getProvList = async () => {
  return http_client.get(config.baseApiUrl + 'api/provinsi').then((response) => {
    return response.data;
  });
};
const readProv = async (id) => {
  return http_client.get(config.baseApiUrl + 'api/provinsi/' + id).then((response) => {
    return response.data;
  });
};
const getKabList = async (provID) => {
  return http_client.get(config.baseApiUrl + 'api/provinsi/' + provID + '/kabupaten').then((response) => {
    return response.data;
  });
};
const readKab = async (provID, id) => {
  return http_client.get(config.baseApiUrl + 'api/provinsi/' + provID + '/kabupaten' + id).then((response) => {
    return response.data;
  });
};

const WilayahService = {
  getProvList,
  readProv,
  getKabList,
  readKab
};

export default WilayahService;
