import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import VideoServices from '../../services/videoServices';
import { clearAuth } from 'store/auth';

export const getList = createAsyncThunk('video/list', async (query, thunkAPI) => {
  try {
    const response = await VideoServices.getList(query);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const readData = createAsyncThunk('video/read', async (id, thunkAPI) => {
  try {
    const response = await VideoServices.read(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getFile = createAsyncThunk('video/getFile', async (id, thunkAPI) => {
  try {
    const response = await VideoServices.getFile(id);
    return response;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getRating = createAsyncThunk('video/getRating', async (id, thunkAPI) => {
  try {
    const response = await VideoServices.getRating(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const storeRating = createAsyncThunk('video/storeRating', async (data, thunkAPI) => {
  try {
    const response = await VideoServices.storeRating(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const createSession = createAsyncThunk('video/createSession', async (id, thunkAPI) => {
  try {
    const response = await VideoServices.createSession(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getSession = createAsyncThunk('video/getSession', async ({ id, sessionId }, thunkAPI) => {
  try {
    const response = await VideoServices.getSession(id, sessionId);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const updateSession = createAsyncThunk('video/updateSession', async ({ id, sessionId, data }, thunkAPI) => {
  try {
    const response = await VideoServices.updateSession(id, sessionId, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const closeSession = createAsyncThunk('video/closeSession', async ({ id, sessionId }, thunkAPI) => {
  try {
    const response = await VideoServices.closeSession(id, sessionId);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const storeAnswer = createAsyncThunk('video/storeAnswer', async ({ id, sessionId, questionId, data }, thunkAPI) => {
  try {
    const response = await VideoServices.storeAnswer(id, sessionId, questionId, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getHistory = createAsyncThunk('video/getHistory', async (id, thunkAPI) => {
  try {
    const response = await VideoServices.getHistory(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  list: [],
  data: null,
  group: [],
  error: [],
  rating: null,
  question: null,
  session: null,
  history: null
};

const videoSlice = createSlice({
  name: 'video',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
        state.data = null;
        state.group = [];
        state.error = [];
      }),
      builder.addCase(getList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.list = [];
        state.group = [];
        state.error = [];
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getFile.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getFile.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getFile.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getRating.pending, (state) => {
        state.isLoading = true;
        state.rating = null;
      }),
      builder.addCase(getRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rating = action?.payload;
      }),
      builder.addCase(getRating.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(storeRating.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(createSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(createSession.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(createSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(closeSession.pending, (state) => {
        state.isLoading = true;
        state.session = null;
      }),
      builder.addCase(closeSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.session = action.payload;
      }),
      builder.addCase(closeSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getSession.pending, (state) => {
        state.isLoading = true;
        state.question = null;
        state.data = null;
        state.session = null;
      }),
      builder.addCase(getSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.question = action.payload?.question;
        state.data = action.payload?.data;
        state.session = action.payload?.session;
      }),
      builder.addCase(getSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getHistory.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload?.data;
        state.history = action.payload?.session;
      }),
      builder.addCase(getHistory.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeAnswer.pending, (state) => {
        state.isCreateLoading = true;
      }),
      builder.addCase(storeAnswer.fulfilled, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswer.rejected, (state) => {
        state.isCreateLoading = false;
      });
  }
});

const { reducer } = videoSlice;
export default reducer;
