import config from 'config';
import { http_client } from './api';

const tahunajaran = async () => {
  return http_client.get(config.baseApiUrl + 'api/tahunajaran').then((response) => {
    return response.data;
  });
};

const sekolah = async (jenjang) => {
  return http_client.get(config.baseApiUrl + 'api/sekolah/' + jenjang).then((response) => {
    return response.data;
  });
};

const cabang = async () => {
  return http_client.get(config.baseApiUrl + 'api/cabang').then((response) => {
    return response.data;
  });
};

const jenjang = async () => {
  return http_client.get(config.baseApiUrl + 'api/jenjang').then((response) => {
    return response.data;
  });
};

const mapel = async (jenjangID) => {
  return http_client.get(config.baseApiUrl + 'api/mapel/' + jenjangID).then((response) => {
    return response.data;
  });
};

const bab = async (mapelID) => {
  return http_client.get(config.baseApiUrl + 'api/bab/' + mapelID).then((response) => {
    return response.data;
  });
};

const subbab = async (babID) => {
  return http_client.get(config.baseApiUrl + 'api/subbab/' + babID).then((response) => {
    return response.data;
  });
};

const studentType = async () => {
  return http_client.get(config.baseApiUrl + 'api/student-type').then((response) => {
    return response.data;
  });
};

const subscriptionType = async (except = null) => {
  return http_client.get(config.baseApiUrl + 'api/subscription-type?' + `${except ? '&except=' + except : ''}`).then((response) => {
    return response.data;
  });
};

const classType = async () => {
  return http_client.get(config.baseApiUrl + 'api/class-type').then((response) => {
    return response.data;
  });
};

const schoolType = async () => {
  return http_client.get(config.baseApiUrl + 'api/school-type').then((response) => {
    return response.data;
  });
};

const getBase64File = async (data) => {
  return http_client.post(config.baseApiUrl + 'api/base64', data).then((response) => {
    return response.data;
  });
};

const CabangService = {
  tahunajaran,
  sekolah,
  cabang,
  jenjang,
  mapel,
  bab,
  subbab,
  studentType,
  subscriptionType,
  classType,
  schoolType,
  getBase64File
};

export default CabangService;
