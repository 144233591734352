import axios from 'axios';
import config from 'config';

const token = localStorage.getItem('studentToken');

// const prod = process.env.NODE_ENV === 'production'

export const service = axios.create({
  baseURL: config.apiUrl
  //   withCredentials: true,
  // timeout: 60000
});
service.interceptors.request.use((config) => {
  config.headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token
  };
  return config;
});

export const http_client = {
  get: (path, queryParams, setProgress = null) => {
    return service.get(path, {
      params: queryParams,
      onDownloadProgress: (progressEvent) => {
        let progress = ((progressEvent?.loaded ?? 1) / (progressEvent?.total ?? 1)) * 100;
        if (setProgress != null) {
          setProgress(progress);
        } else {
          return progress;
        }
      }
    });
  },
  getFile: (path, queryParams) => {
    return service.get(path, {
      params: queryParams,
      responseType: 'blob'
    });
  },
  post: (path, body, queryParams, setProgress = null) => {
    return service.post(path, body, {
      params: queryParams,
      onUploadProgress: (progressEvent) => {
        let progress = ((progressEvent?.loaded ?? 1) / (progressEvent?.total ?? 1)) * 100;
        if (setProgress != null) {
          setProgress(progress);
        } else {
          return progress;
        }
      }
    });
  },
  patch: (path, body) => {
    return service.patch(path, body);
  },
  delete: (path) => {
    return service.delete(path);
  }
};
