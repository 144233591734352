import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import ExamArchServices from '../../services/examArchServices';
import { clearAuth } from 'store/auth';

export const getList = createAsyncThunk(
  'examarch/list',
  async ({ page, perPage, sort, orderBy, search, jenjang_id, mapel_id, type }, thunkAPI) => {
    try {
      const response = await ExamArchServices.getList(page, perPage, sort, orderBy, search, jenjang_id, mapel_id, type);
      return response.data;
    } catch (error) {
      if (error?.response?.status == 401) {
        thunkAPI.dispatch(clearAuth());
      }
      const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
      thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const readData = createAsyncThunk('examarch/read', async (id, thunkAPI) => {
  try {
    const response = await ExamArchServices.read(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const createSession = createAsyncThunk('examarch/createSession', async (data, thunkAPI) => {
  try {
    const response = await ExamArchServices.createSession(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const repeatSession = createAsyncThunk('examarch/repeatSession', async (id, thunkAPI) => {
  try {
    const response = await ExamArchServices.repeatSession(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getSession = createAsyncThunk('examarch/getSession', async ({ id, sessionId }, thunkAPI) => {
  try {
    const response = await ExamArchServices.getSession(id, sessionId);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const closeSession = createAsyncThunk('examarch/closeSession', async ({ id, sessionId }, thunkAPI) => {
  try {
    const response = await ExamArchServices.closeSession(id, sessionId);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const storeAnswer = createAsyncThunk('examarch/storeAnswer', async ({ id, sessionId, questionId, data }, thunkAPI) => {
  try {
    const response = await ExamArchServices.storeAnswer(id, sessionId, questionId, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getHistory = createAsyncThunk('examarch/getHistory', async (id, thunkAPI) => {
  try {
    const response = await ExamArchServices.getHistory(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getRating = createAsyncThunk('examarch/getRating', async (id, thunkAPI) => {
  try {
    const response = await ExamArchServices.getRating(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const storeRating = createAsyncThunk('examarch/storeRating', async (data, thunkAPI) => {
  try {
    const response = await ExamArchServices.storeRating(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

const initialState = { isLoading: true, isCreateLoading: false, list: [], session: null, question: [], data: [], error: [], rating: null };

const examarchSlice = createSlice({
  name: 'examarch',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      }),
      builder.addCase(getList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(createSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(createSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(createSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(repeatSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(repeatSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(repeatSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(closeSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(closeSession.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(closeSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.question = action.payload?.question;
        state.data = action.payload?.data;
        state.session = action.payload?.session;
      }),
      builder.addCase(getSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getHistory.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload?.data;
        state.history = action.payload?.session;
      }),
      builder.addCase(getHistory.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeAnswer.pending, (state) => {
        state.isCreateLoading = true;
      }),
      builder.addCase(storeAnswer.fulfilled, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(storeAnswer.rejected, (state) => {
        state.isCreateLoading = false;
      }),
      builder.addCase(getRating.pending, (state) => {
        state.isLoading = true;
        state.rating = null;
      }),
      builder.addCase(getRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rating = action?.payload;
      }),
      builder.addCase(getRating.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(storeRating.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer } = examarchSlice;
export default reducer;
