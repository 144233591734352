import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import PaymentService from '../../services/paymentServices';
import { clearAuth } from 'store/auth';

export const getList = createAsyncThunk('payment/list', async ({ page, perPage, sort, orderBy, search, status }, thunkAPI) => {
  try {
    const response = await PaymentService.getList(page, perPage, sort, orderBy, search, status);

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const readData = createAsyncThunk('payment/read', async (id, thunkAPI) => {
  try {
    const response = await PaymentService.read(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const encryptRead = createAsyncThunk('payment/encryptRead', async (id, thunkAPI) => {
  try {
    const response = await PaymentService.encryptRead(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

const initialState = { isLoading: false, list: [], data: [] };

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      }),
      builder.addCase(getList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(encryptRead.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(encryptRead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(encryptRead.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer } = paymentSlice;
export default reducer;
