import config from 'config';
import { http_client } from './api';

const getList = async (query) => {
  return http_client.get(config.apiUrl + 'video/all', query).then((response) => {
    return response.data;
  });
};

const read = async (id) => {
  return http_client.get(config.apiUrl + 'video/' + id).then((response) => {
    return response.data;
  });
};

const getFile = async (id) => {
  return http_client.getFile(config.apiUrl + 'video/file/' + id).then((response) => {
    return response.data;
  });
};

const getRating = async (id) => {
  return http_client.get(config.apiUrl + 'video/' + id + '/rating').then((response) => {
    return response.data;
  });
};

const storeRating = async (id, data) => {
  return http_client.post(config.apiUrl + 'video/' + id + '/rating', data).then((response) => {
    return response.data;
  });
};

const createSession = async (id, data = null) => {
  return http_client.post(config.apiUrl + 'video/' + id + '/session', data).then((response) => {
    return response.data;
  });
};

const getSession = async (id, sessionId) => {
  return http_client.get(config.apiUrl + 'video/' + id + '/session/' + sessionId).then((response) => {
    return response.data;
  });
};

const updateSession = async (id, sessionId, data) => {
  return http_client.patch(config.apiUrl + 'video/' + id + '/session/' + sessionId, data).then((response) => {
    return response.data;
  });
};

const closeSession = async (id, sessionId) => {
  return http_client.post(config.apiUrl + 'video/' + id + '/session/' + sessionId).then((response) => {
    return response.data;
  });
};

const getHistory = async (id) => {
  return http_client.get(config.apiUrl + 'video/' + id + '/history').then((response) => {
    return response.data;
  });
};

const storeAnswer = async (id, sessionId, questionId, data) => {
  return http_client.post(config.apiUrl + 'video/' + id + '/session/' + sessionId + '/question/' + questionId, data).then((response) => {
    return response.data;
  });
};

const MateriService = {
  getList,
  read,
  getFile,
  getRating,
  storeRating,
  createSession,
  getSession,
  updateSession,
  closeSession,
  getHistory,
  storeAnswer
};

export default MateriService;
