import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message';
import MatchWordServices from '../../services/matchwordServices';
import { clearAuth } from 'store/auth';

export const getList = createAsyncThunk('matchword/list', async ({ page, perPage, sort, orderBy, search }, thunkAPI) => {
  try {
    const response = await MatchWordServices.getList(page, perPage, sort, orderBy, search);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue();
  }
});

export const readData = createAsyncThunk('matchword/read', async (id, thunkAPI) => {
  try {
    const response = await MatchWordServices.read(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const createSession = createAsyncThunk('matchword/createSession', async (data, thunkAPI) => {
  try {
    const response = await MatchWordServices.createSession(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const repeatSession = createAsyncThunk('matchword/repeatSession', async (data, thunkAPI) => {
  try {
    const response = await MatchWordServices.repeatSession(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const closeSession = createAsyncThunk('matchword/closeSession', async (data, thunkAPI) => {
  try {
    const response = await MatchWordServices.closeSession(data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getHistory = createAsyncThunk('matchword/getHistory', async (matchwordID, thunkAPI) => {
  try {
    const response = await MatchWordServices.getHistory(matchwordID);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getSession = createAsyncThunk('matchword/getSession', async ({ id, sessionID }, thunkAPI) => {
  try {
    const response = await MatchWordServices.getSession(id, sessionID);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const getRating = createAsyncThunk('matchword/getRating', async (id, thunkAPI) => {
  try {
    const response = await MatchWordServices.getRating(id);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const storeRating = createAsyncThunk('matchword/storeRating', async (data, thunkAPI) => {
  try {
    const response = await MatchWordServices.storeRating(data.id, data);
    return response.data;
  } catch (error) {
    if (error?.response?.status == 401) {
      thunkAPI.dispatch(clearAuth());
    }
    const message = (error.response && error?.response?.data && error?.response?.data.message) || error.message || error.toString();
    thunkAPI.dispatch(setMessage({ message: message, type: 'error' }));
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

const initialState = {
  isLoading: true,
  list: [],
  data: [],
  session: [],
  history: [],
  error: [],
  rating: null
};

const matchwordSlice = createSlice({
  name: 'matchword',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(getList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      }),
      builder.addCase(getList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(readData.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(readData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(readData.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(createSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(createSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(createSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(repeatSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(repeatSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      }),
      builder.addCase(repeatSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(closeSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(closeSession.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(closeSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getHistory.pending, (state) => {
        state.isLoading = true;
        state.data = null;
        state.history = null;
      }),
      builder.addCase(getHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.matchword;
        state.history = action.payload.session;
      }),
      builder.addCase(getHistory.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getSession.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.session = action.payload.session;
      }),
      builder.addCase(getSession.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(getRating.pending, (state) => {
        state.isLoading = true;
        state.rating = null;
      }),
      builder.addCase(getRating.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rating = action?.payload;
      }),
      builder.addCase(getRating.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(storeRating.fulfilled, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(storeRating.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

const { reducer } = matchwordSlice;
export default reducer;
