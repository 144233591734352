import dashboard from './dashboard';
import learning from './learning';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, learning]
};

export default menuItems;
